












import { Vue, Component, Prop } from "vue-property-decorator"

// type State = 'unselected' | 'mandatory' | 'forbidden' | 'important' | 'high priority'

export enum SearchTagState {
  unselected,
  selected,
  mandatory,
  forbidden,
  length, // number of elements in the enum
}

export const stateColors = {
  [SearchTagState.unselected]: "transparent",
  [SearchTagState.selected]: "orange",
  [SearchTagState.forbidden]: "error",
  [SearchTagState.mandatory]: "info",
  [SearchTagState.length]: "",
}

export const stateIcons = {
  [SearchTagState.unselected]: "",
  [SearchTagState.selected]: "mdi-cog",
  [SearchTagState.forbidden]: "mdi-minus",
  [SearchTagState.mandatory]: "mdi-plus",
  [SearchTagState.length]: "",
}

@Component({})
export default class SearchTagChip extends Vue {
  @Prop({ required: true }) label!: string
  @Prop({ default: false }) unselectable!: boolean
  @Prop({ default: SearchTagState.unselected }) state!: SearchTagState
  @Prop({ default: false }) small!: boolean
  @Prop({ default: false }) closable!: boolean

  get color(): string {
    return stateColors[this.state] || "transparent"
  }

  get icon(): string {
    return stateIcons[this.state] || ""
  }

  changeState(event: MouseEvent) {
    event.preventDefault()
    event.stopPropagation()
    this.$emit("click")
  }

  close(event: any) {
    event.stopPropagation()
    this.$emit("close")
  }
}
