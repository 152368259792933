




















import { Vue, Component, Prop } from "vue-property-decorator"
import { Tag } from "~/config/algolia"
import SearchTagChip, {
  SearchTagState,
} from "@/components/search/SearchTagChip.vue"
import { state } from "@/store"
import { TagCategory } from "~/store/tags"

type CheckboxTag = {
  id: number
  name: string
  checked: boolean
}

const stateColors = {
  [SearchTagState.unselected]: "primary",
  [SearchTagState.selected]: "orange",
  [SearchTagState.forbidden]: "error",
  [SearchTagState.mandatory]: "info",
  [SearchTagState.length]: "",
}

export type SelectTagsModel = { [key: number]: SearchTagState }

@Component({
  components: {
    SearchTagChip,
  },
})
export default class SelectTags extends Vue {
  @Prop({ required: true }) category!: TagCategory
  @Prop({ required: true }) value!: SelectTagsModel
  tags: CheckboxTag[] = []

  mounted() {
    const value = () => this.value // little hack so that this.value is accessible to the getters and setters below
    // const emit = (event: string, value: any) => this.$emit(event, value)

    state.tags.onceLoaded((tags) => {
      this.tags = tags[this.category].map((tag) => ({
        id: tag.id,
        name: tag.name,
        get checked(): boolean {
          return this.id in value() && !!value()[this.id]
        },
        set checked(_: boolean) {
          if (this.checked) value()[this.id] = SearchTagState.unselected
          else value()[this.id] = SearchTagState.selected
          // emit('input', value())
        },
      }))
    })
  }

  checkboxColor(tag: Tag) {
    return stateColors[this.value[tag.id]]
  }

  changeTagState(tag: Tag) {
    this.value[tag.id] = ((this.value[tag.id] || 0) + 1) % SearchTagState.length
    this.$forceUpdate() // that shouldn't be needed but it actually is
    // this.$emit('input', this.value)
  }
}
